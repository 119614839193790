export const trainerLevels = [
    {
        level: 1,
        rewards: [],
        experience: 0
    },
    {
        level: 2,
        experience: 1000,
        rewards: [
            {
                count: 10,
                item: "Poké Ball"
            },
            {
                count: 6,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 3,
        experience: 3000,
        rewards: [
            {
                count: 15,
                item: "Poké Ball"
            },
            {
                count: 8,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 4,
        experience: 6000,
        rewards: [
            {
                count: 15,
                item: "Poké Ball"
            },
            {
                count: 15,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 5,
        experience: 10000,
        rewards: [
            {
                count: 20,
                item: "Poké Ball"
            },
            {
                count: 10,
                item: "Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 1,
                item: "Incense"
            }
        ]
    },
    {
        level: 6,
        experience: 15000,
        rewards: [
            {
                count: 15,
                item: "Poké Ball"
            },
            {
                count: 10,
                item: "Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 1,
                item: "Egg Incubator"
            }
        ]
    },
    {
        level: 7,
        experience: 21000,
        rewards: [
            {
                count: 15,
                item: "Poké Ball"
            },
            {
                count: 10,
                item: "Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 1,
                item: "Incense"
            }
        ]
    },
    {
        level: 8,
        experience: 28000,
        rewards: [
            {
                count: 15,
                item: "Poké Ball"
            },
            {
                count: 10,
                item: "Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 10,
                item: "Razz Berry"
            },
            {
                count: 1,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 9,
        experience: 36000,
        rewards: [
            {
                count: 15,
                item: "Poké Ball"
            },
            {
                count: 10,
                item: "Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 3,
                item: "Razz Berry"
            },
            {
                count: 1,
                item: "Lucky Egg"
            }
        ]
    },
    {
        level: 10,
        experience: 45000,
        rewards: [
            {
                count: 20,
                item: "Poké Ball"
            },
            {
                count: 20,
                item: "Super Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 10,
                item: "Razz Berry"
            },
            {
                count: 1,
                item: "Incense"
            },
            {
                count: 1,
                item: "Lucky Egg"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 11,
        experience: 55000,
        rewards: [
            {
                count: 15,
                item: "Poké Ball"
            },
            {
                count: 10,
                item: "Super Potion"
            },
            {
                count: 3,
                item: "Revive"
            },
            {
                count: 3,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 12,
        experience: 65000,
        rewards: [
            {
                count: 20,
                item: "Great Ball"
            },
            {
                count: 10,
                item: "Super Potion"
            },
            {
                count: 3,
                item: "Revive"
            },
            {
                count: 3,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 13,
        experience: 75000,
        rewards: [
            {
                count: 10,
                item: "Great Ball"
            },
            {
                count: 10,
                item: "Super Potion"
            },
            {
                count: 3,
                item: "Revive"
            },
            {
                count: 3,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 14,
        experience: 85000,
        rewards: [
            {
                count: 10,
                item: "Great Ball"
            },
            {
                count: 10,
                item: "Super Potion"
            },
            {
                count: 3,
                item: "Revive"
            },
            {
                count: 3,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 15,
        experience: 100000,
        rewards: [
            {
                count: 15,
                item: "Great Ball"
            },
            {
                count: 20,
                item: "Hyper Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 10,
                item: "Razz Berry"
            },
            {
                count: 1,
                item: "Incense"
            },
            {
                count: 1,
                item: "Lucky Egg"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 16,
        experience: 120000,
        rewards: [
            {
                count: 10,
                item: "Great Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 5,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 17,
        experience: 140000,
        rewards: [
            {
                count: 10,
                item: "Great Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 5,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 18,
        experience: 160000,
        rewards: [
            {
                count: 10,
                item: "Great Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 5,
                item: "Pinap Berry"
            }
        ]
    },
    {
        level: 19,
        experience: 185000,
        rewards: [
            {
                count: 15,
                item: "Great Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 5,
                item: "Revive"
            },
            {
                count: 5,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 20,
        experience: 210000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Hyper Potion"
            },
            {
                count: 20,
                item: "Revive"
            },
            {
                count: 20,
                item: "Nanab Berry"
            },
            {
                count: 2,
                item: "Incense"
            },
            {
                count: 2,
                item: "Lucky Egg"
            },
            {
                count: 2,
                item: "Egg Incubator"
            },
            {
                count: 2,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 21,
        experience: 260000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 10,
                item: "Pinap Berry"
            }
        ]
    },
    {
        level: 22,
        experience: 335000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 10,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 23,
        experience: 435000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 10,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 24,
        experience: 560000,
        rewards: [
            {
                count: 15,
                item: "Ultra Ball"
            },
            {
                count: 10,
                item: "Hyper Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 10,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 25,
        experience: 710000,
        rewards: [
            {
                count: 25,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 15,
                item: "Revive"
            },
            {
                count: 15,
                item: "Pinap Berry"
            },
            {
                count: 1,
                item: "Incense"
            },
            {
                count: 1,
                item: "Lucky Egg"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 26,
        experience: 900000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 15,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 27,
        experience: 1100000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 15,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 28,
        experience: 1350000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 15,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 29,
        experience: 1650000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Revive"
            },
            {
                count: 15,
                item: "Pinap Berry"
            }
        ]
    },
    {
        level: 30,
        experience: 2000000,
        rewards: [
            {
                count: 30,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Razz Berry"
            },
            {
                count: 3,
                item: "Incense"
            },
            {
                count: 3,
                item: "Lucky Egg"
            },
            {
                count: 3,
                item: "Egg Incubator"
            },
            {
                count: 3,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 31,
        experience: 2500000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 15,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 32,
        experience: 3000000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 15,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 33,
        experience: 3750000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 15,
                item: "Pinap Berry"
            }
        ]
    },
    {
        level: 34,
        experience: 4750000,
        rewards: [
            {
                count: 10,
                item: "Ultra Ball"
            },
            {
                count: 15,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 15,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 35,
        experience: 6000000,
        rewards: [
            {
                count: 30,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Nanab Berry"
            },
            {
                count: 2,
                item: "Incense"
            },
            {
                count: 1,
                item: "Lucky Egg"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 36,
        experience: 7500000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 37,
        experience: 9500000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Pinap Berry"
            }
        ]
    },
    {
        level: 38,
        experience: 12000000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Razz Berry"
            }
        ]
    },
    {
        level: 39,
        experience: 15000000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 10,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Nanab Berry"
            }
        ]
    },
    {
        level: 40,
        experience: 20000000,
        rewards: [
            {
                count: 40,
                item: "Ultra Ball"
            },
            {
                count: 40,
                item: "Max Potion"
            },
            {
                count: 40,
                item: "Max Revive"
            },
            {
                count: 40,
                item: "Razz Berry"
            },
            {
                count: 4,
                item: "Incense"
            },
            {
                count: 4,
                item: "Lucky Egg"
            },
            {
                count: 4,
                item: "Egg Incubator"
            },
            {
                count: 4,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 41,
        experience: 26000000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Razz Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 42,
        experience: 33500000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Nanab Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 43,
        experience: 42500000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Silver Pinap Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 44,
        experience: 53500000,
        rewards: [
            {
                count: 20,
                item: "Ultra Ball"
            },
            {
                count: 20,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 20,
                item: "Razz Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 45,
        experience: 66500000,
        rewards: [
            {
                count: 40,
                item: "Ultra Ball"
            },
            {
                count: 40,
                item: "Max Revive"
            },
            {
                count: 1,
                item: "Elite Fast TM"
            },
            {
                count: 2,
                item: "XL Rare Candy"
            },
            {
                count: 2,
                item: "Incense"
            },
            {
                count: 2,
                item: "Lucky Egg"
            },
            {
                count: 1,
                item: "Super Incubator"
            },
            {
                count: 2,
                item: "Lure Module"
            }
        ]
    },
    {
        level: 46,
        experience: 82000000,
        rewards: [
            {
                count: 30,
                item: "Ultra Ball"
            },
            {
                count: 25,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 25,
                item: "Razz Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 47,
        experience: 100000000,
        rewards: [
            {
                count: 30,
                item: "Ultra Ball"
            },
            {
                count: 25,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 25,
                item: "Nanab Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 48,
        experience: 121000000,
        rewards: [
            {
                count: 30,
                item: "Ultra Ball"
            },
            {
                count: 25,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 15,
                item: "Silver Pinap Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 49,
        experience: 146000000,
        rewards: [
            {
                count: 30,
                item: "Ultra Ball"
            },
            {
                count: 25,
                item: "Max Potion"
            },
            {
                count: 20,
                item: "Max Revive"
            },
            {
                count: 25,
                item: "Pinap Berry"
            },
            {
                count: 1,
                item: "Egg Incubator"
            },
            {
                count: 1,
                item: "Premium Battle Pass"
            },
            {
                count: 1,
                item: "XL Rare Candy"
            }
        ]
    },
    {
        level: 50,
        experience: 176000000,
        rewards: [
            {
                count: 50,
                item: "Ultra Ball"
            },
            {
                count: 50,
                item: "Max Potion"
            },
            {
                count: 1,
                item: "Elite Charged TM"
            },
            {
                count: 2,
                item: "XL Rare Candy"
            },
            {
                count: 5,
                item: "Incense"
            },
            {
                count: 5,
                item: "Lucky Egg"
            },
            {
                count: 5,
                item: "Super Incubator"
            },
            {
                count: 5,
                item: "Lure Module"
            }
        ]
    }
]

export const newLevels = [
    {
        "level": 1,
        "rewards": [],
        "totalExperience": 0,
        experience: 0
    },
    {
        "level": 2,
        "rewards": [
            {
                "count": 10,
                "item": "Poké Ball"
            },
            {
                "count": 6,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 1000,
        experience: 1000
    },
    {
        "level": 3,
        "rewards": [
            {
                "count": 15,
                "item": "Poké Ball"
            },
            {
                "count": 8,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 3000,
        experience: 2000
    },
    {
        "level": 4,
        "rewards": [
            {
                "count": 15,
                "item": "Poké Ball"
            },
            {
                "count": 15,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 6000,
        experience: 3000
    },
    {
        "level": 5,
        "rewards": [
            {
                "count": 20,
                "item": "Poké Ball"
            },
            {
                "count": 10,
                "item": "Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 1,
                "item": "Incense"
            }
        ],
        "totalExperience": 10000,
        experience: 4000
    },
    {
        "level": 6,
        "rewards": [
            {
                "count": 15,
                "item": "Poké Ball"
            },
            {
                "count": 10,
                "item": "Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            }
        ],
        "totalExperience": 15000,
        experience: 5000
    },
    {
        "level": 7,
        "rewards": [
            {
                "count": 15,
                "item": "Poké Ball"
            },
            {
                "count": 10,
                "item": "Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 1,
                "item": "Incense"
            }
        ],
        "totalExperience": 21000,
        experience: 6000
    },
    {
        "level": 8,
        "rewards": [
            {
                "count": 15,
                "item": "Poké Ball"
            },
            {
                "count": 10,
                "item": "Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 10,
                "item": "Razz Berry"
            },
            {
                "count": 1,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 28000,
        experience: 7000
    },
    {
        "level": 9,
        "rewards": [
            {
                "count": 15,
                "item": "Poké Ball"
            },
            {
                "count": 10,
                "item": "Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 3,
                "item": "Razz Berry"
            },
            {
                "count": 1,
                "item": "Lucky Egg"
            }
        ],
        "totalExperience": 36000,
        experience: 8000
    },
    {
        "level": 10,
        "rewards": [
            {
                "count": 20,
                "item": "Poké Ball"
            },
            {
                "count": 20,
                "item": "Super Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 10,
                "item": "Razz Berry"
            },
            {
                "count": 1,
                "item": "Incense"
            },
            {
                "count": 1,
                "item": "Lucky Egg"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 45000,
        experience: 9000
    },
    {
        "level": 11,
        "rewards": [
            {
                "count": 15,
                "item": "Poké Ball"
            },
            {
                "count": 10,
                "item": "Super Potion"
            },
            {
                "count": 3,
                "item": "Revive"
            },
            {
                "count": 3,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 55000,
        experience: 10000
    },
    {
        "level": 12,
        "rewards": [
            {
                "count": 20,
                "item": "Great Ball"
            },
            {
                "count": 10,
                "item": "Super Potion"
            },
            {
                "count": 3,
                "item": "Revive"
            },
            {
                "count": 3,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 65000,
        experience: 10000
    },
    {
        "level": 13,
        "rewards": [
            {
                "count": 10,
                "item": "Great Ball"
            },
            {
                "count": 10,
                "item": "Super Potion"
            },
            {
                "count": 3,
                "item": "Revive"
            },
            {
                "count": 3,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 75000,
        experience: 10000
    },
    {
        "level": 14,
        "rewards": [
            {
                "count": 10,
                "item": "Great Ball"
            },
            {
                "count": 10,
                "item": "Super Potion"
            },
            {
                "count": 3,
                "item": "Revive"
            },
            {
                "count": 3,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 85000,
        experience: 10000
    },
    {
        "level": 15,
        "rewards": [
            {
                "count": 15,
                "item": "Great Ball"
            },
            {
                "count": 20,
                "item": "Hyper Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 10,
                "item": "Razz Berry"
            },
            {
                "count": 1,
                "item": "Incense"
            },
            {
                "count": 1,
                "item": "Lucky Egg"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 100000,
        experience: 15000
    },
    {
        "level": 16,
        "rewards": [
            {
                "count": 10,
                "item": "Great Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 5,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 120000,
        experience: 20000
    },
    {
        "level": 17,
        "rewards": [
            {
                "count": 10,
                "item": "Great Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 5,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 140000,
        experience: 20000
    },
    {
        "level": 18,
        "rewards": [
            {
                "count": 10,
                "item": "Great Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 5,
                "item": "Pinap Berry"
            }
        ],
        "totalExperience": 160000,
        experience: 20000
    },
    {
        "level": 19,
        "rewards": [
            {
                "count": 15,
                "item": "Great Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 5,
                "item": "Revive"
            },
            {
                "count": 5,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 185000,
        experience: 25000
    },
    {
        "level": 20,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Hyper Potion"
            },
            {
                "count": 20,
                "item": "Revive"
            },
            {
                "count": 20,
                "item": "Nanab Berry"
            },
            {
                "count": 2,
                "item": "Incense"
            },
            {
                "count": 2,
                "item": "Lucky Egg"
            },
            {
                "count": 2,
                "item": "Egg Incubator"
            },
            {
                "count": 2,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 210000,
        experience: 25000
    },
    {
        "level": 21,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 10,
                "item": "Pinap Berry"
            }
        ],
        "totalExperience": 260000,
        experience: 50000
    },
    {
        "level": 22,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 10,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 335000,
        experience: 75000
    },
    {
        "level": 23,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 10,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 435000,
        experience: 100000
    },
    {
        "level": 24,
        "rewards": [
            {
                "count": 15,
                "item": "Ultra Ball"
            },
            {
                "count": 10,
                "item": "Hyper Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 10,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 560000,
        experience: 125000
    },
    {
        "level": 25,
        "rewards": [
            {
                "count": 25,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 15,
                "item": "Revive"
            },
            {
                "count": 15,
                "item": "Pinap Berry"
            },
            {
                "count": 1,
                "item": "Incense"
            },
            {
                "count": 1,
                "item": "Lucky Egg"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 710000,
        experience: 150000
    },
    {
        "level": 26,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 15,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 900000,
        experience: 190000
    },
    {
        "level": 27,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 15,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 1100000,
        experience: 200000
    },
    {
        "level": 28,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 15,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 1350000,
        experience: 250000
    },
    {
        "level": 29,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Revive"
            },
            {
                "count": 15,
                "item": "Pinap Berry"
            }
        ],
        "totalExperience": 1650000,
        experience: 300000
    },
    {
        "level": 30,
        "rewards": [
            {
                "count": 30,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Razz Berry"
            },
            {
                "count": 3,
                "item": "Incense"
            },
            {
                "count": 3,
                "item": "Lucky Egg"
            },
            {
                "count": 3,
                "item": "Egg Incubator"
            },
            {
                "count": 3,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 2000000,
        experience: 350000
    },
    {
        "level": 31,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 15,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 2500000,
        experience: 500000
    },
    {
        "level": 32,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 15,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 3000000,
        experience: 500000
    },
    {
        "level": 33,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 15,
                "item": "Pinap Berry"
            }
        ],
        "totalExperience": 3750000,
        experience: 750000
    },
    {
        "level": 34,
        "rewards": [
            {
                "count": 10,
                "item": "Ultra Ball"
            },
            {
                "count": 15,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 15,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 4750000,
        experience: 1000000
    },
    {
        "level": 35,
        "rewards": [
            {
                "count": 30,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Nanab Berry"
            },
            {
                "count": 2,
                "item": "Incense"
            },
            {
                "count": 1,
                "item": "Lucky Egg"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 6000000,
        experience: 1250000
    },
    {
        "level": 36,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 7500000,
        experience: 1500000
    },
    {
        "level": 37,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Pinap Berry"
            }
        ],
        "totalExperience": 9500000,
        experience: 2000000
    },
    {
        "level": 38,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Razz Berry"
            }
        ],
        "totalExperience": 12000000,
        experience: 2500000
    },
    {
        "level": 39,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 10,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Nanab Berry"
            }
        ],
        "totalExperience": 15000000,
        experience: 3000000
    },
    {
        "level": 40,
        "rewards": [
            {
                "count": 40,
                "item": "Ultra Ball"
            },
            {
                "count": 40,
                "item": "Max Potion"
            },
            {
                "count": 40,
                "item": "Max Revive"
            },
            {
                "count": 40,
                "item": "Razz Berry"
            },
            {
                "count": 4,
                "item": "Incense"
            },
            {
                "count": 4,
                "item": "Lucky Egg"
            },
            {
                "count": 4,
                "item": "Egg Incubator"
            },
            {
                "count": 4,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 20000000,
        experience: 5000000
    },
    {
        "level": 41,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Razz Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 26000000,
        experience: 6000000
    },
    {
        "level": 42,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Nanab Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 33500000,
        experience: 7500000
    },
    {
        "level": 43,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Silver Pinap Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 42500000,
        experience: 9000000
    },
    {
        "level": 44,
        "rewards": [
            {
                "count": 20,
                "item": "Ultra Ball"
            },
            {
                "count": 20,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 20,
                "item": "Razz Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 53500000,
        experience: 11000000
    },
    {
        "level": 45,
        "rewards": [
            {
                "count": 40,
                "item": "Ultra Ball"
            },
            {
                "count": 40,
                "item": "Max Revive"
            },
            {
                "count": 1,
                "item": "Elite Fast TM"
            },
            {
                "count": 2,
                "item": "XL Rare Candy"
            },
            {
                "count": 2,
                "item": "Incense"
            },
            {
                "count": 2,
                "item": "Lucky Egg"
            },
            {
                "count": 1,
                "item": "Super Incubator"
            },
            {
                "count": 2,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 66500000,
        experience: 13000000
    },
    {
        "level": 46,
        "rewards": [
            {
                "count": 30,
                "item": "Ultra Ball"
            },
            {
                "count": 25,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 25,
                "item": "Razz Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 82000000,
        experience: 15500000
    },
    {
        "level": 47,
        "rewards": [
            {
                "count": 30,
                "item": "Ultra Ball"
            },
            {
                "count": 25,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 25,
                "item": "Nanab Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 100000000,
        experience: 18000000
    },
    {
        "level": 48,
        "rewards": [
            {
                "count": 30,
                "item": "Ultra Ball"
            },
            {
                "count": 25,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 15,
                "item": "Silver Pinap Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 121000000,
        experience: 21000000
    },
    {
        "level": 49,
        "rewards": [
            {
                "count": 30,
                "item": "Ultra Ball"
            },
            {
                "count": 25,
                "item": "Max Potion"
            },
            {
                "count": 20,
                "item": "Max Revive"
            },
            {
                "count": 25,
                "item": "Pinap Berry"
            },
            {
                "count": 1,
                "item": "Egg Incubator"
            },
            {
                "count": 1,
                "item": "Premium Battle Pass"
            },
            {
                "count": 1,
                "item": "XL Rare Candy"
            }
        ],
        "totalExperience": 146000000,
        experience: 25000000
    },
    {
        "level": 50,
        "rewards": [
            {
                "count": 50,
                "item": "Ultra Ball"
            },
            {
                "count": 50,
                "item": "Max Potion"
            },
            {
                "count": 1,
                "item": "Elite Charged TM"
            },
            {
                "count": 2,
                "item": "XL Rare Candy"
            },
            {
                "count": 5,
                "item": "Incense"
            },
            {
                "count": 5,
                "item": "Lucky Egg"
            },
            {
                "count": 5,
                "item": "Super Incubator"
            },
            {
                "count": 5,
                "item": "Lure Module"
            }
        ],
        "totalExperience": 176000000,
        experience: 30000000
    }
]