import styled from 'styled-components'
import { space, layout, color, variant, SpaceProps, LayoutProps, display, flexbox, DisplayProps, FlexboxProps, backgroundColor, BackgroundColorProps, BordersProps, position, PositionProps, borders } from 'styled-system'

export interface CardProps extends SpaceProps, LayoutProps, BackgroundColorProps, BordersProps, DisplayProps, FlexboxProps, PositionProps {
    variant?: 'default' | 'flat' | 'none'
}

const Card = styled.div<CardProps>`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .05), 0 0 8px 8px rgba(0, 0, 0, .015);
  transition: background .15s ease-out, border .15s ease-out;
  overflow: hidden;

${variant({
    variants: {
        default: {
            borderRadius: 'medium'
        },
        flat: {
            boxShadow: 'none',
            borderRadius: 'medium'
        },
        none: {
            boxShadow: 'none',
            backgroundColor: 'transparent',
            border: 'none'
        }
    }
})}
  ${space}
  ${layout}
  ${color}
  ${backgroundColor}
  ${borders}
  ${display}
  ${flexbox}
  ${position}
`

Card.defaultProps = {
    variant: "default"
}

export default Card