import React, { FC } from "react";
import styled from "styled-components";
import { flexbox, FlexboxProps, layout, LayoutProps, space, SpaceProps } from "styled-system";

type StyledProps = SpaceProps & FlexboxProps

interface Props extends StyledProps {
    value: number;
    max: number;
}

const StatBarContainer = styled.div<StyledProps>`
    height: 8px;
    border-radius: 2px;
    overflow: hidden;
    width: 100%;
    display: block;
    border: 1px solid ${({ theme }) => theme.colors.black};
    background-color: ${({ theme }) => theme.colors.white};
    ${space}
    ${flexbox}
`

const StatBarFill = styled.div<LayoutProps>`
    height: 100%;
    background-color: ${({ theme }) => theme.colors.black};
    ${layout}
`

const StatBar: FC<Props> = ({ value, max, ...rest }) => {
    return (
        <StatBarContainer {...rest}>
            <StatBarFill width={`${value/max * 100}%`} />
        </StatBarContainer>
    )
}

export default StatBar