import React, { FC } from 'react'
import { PageProps } from 'gatsby'

import Heading from '../components/heading'
import SEO from '../components/seo'
import Text from '../components/text'
import Wrapper from '../containers/wrapper'
import { trainerLevels, newLevels } from '../data/trainerLevels'
import Box from '../components/box'
import Card from '../components/card'
import StatBar from '../components/dex/stat-bar'

interface Props extends PageProps {}

const MAX_XP = 176000000

const TrainerLevelsPage: FC<Props> = () => {
  const numberWithCommas = (value: number) => {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  return (
    <Wrapper>
      <SEO title={`Trainer Levels`} />
      <Heading as="h1" fontSize={8} mb={4}>
        Trainer Levels
      </Heading>
      <Text fontSize={2}>
        For each level you are required to reach a specific amount of cumulative xp.
      </Text>
      <Text fontSize={2}>
        Every time you reach the next level in Pokemon Go you are given a small reward package for
        doing so. In addition to this, you potentially unlock new items that can be received during
        the game.
      </Text>

      <Box>
        {newLevels
          .sort((a, b) => b.level - a.level)
          .map((level) => (
            <Card
              as="section"
              key={`trainer-level-${level.level}`}
              mb={3}
              borderRadius="small"
              borderColor="grey.2"
              variant="flat"
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} p={3}>
                <Heading as="h2" fontSize={5}>
                  Level {level.level}
                </Heading>
                <Box>
                  <Heading as="h3" fontSize={3} fontWeight="normal" mb={1}>
                    XP Required: <strong>{numberWithCommas(level.experience)}</strong>
                  </Heading>
                  <Heading as="h3" fontSize={3} fontWeight="normal">
                    Total XP: <strong>{numberWithCommas(level.totalExperience)}</strong>
                  </Heading>
                </Box>
              </Box>
              <Box px={3}>
                <StatBar max={MAX_XP} value={level.totalExperience} />
              </Box>
              {level.rewards.length > 0 && (
                <Box p={3} py={4} borderTop="1px solid" borderTopColor="grey.1">
                  <Heading as="h3" fontSize={2} mb={2}>
                    Rewards:
                  </Heading>
                  <Box display="grid" gridTemplateColumns="1fr 1fr">
                    {level.rewards.map((reward) => (
                      <Text as="h4" fontSize={2} color="grey.6" mb={0}>
                        x{reward.count} - {reward.item}
                      </Text>
                    ))}
                  </Box>
                </Box>
              )}
            </Card>
          ))}
      </Box>
    </Wrapper>
  )
}

export default TrainerLevelsPage
